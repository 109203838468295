<template>
  <section>
    <!-- ------------------------ -->
    <!-- Clinic Informations -->
    <!-- ------------------------ -->
    <div class="d-flex my-2 align-items-center">
      <span class="ms-0 me-3 fw-bold f14">{{$t('Clinic information')}}</span>
      <v-divider></v-divider>
    </div>

    <div class="d-flex justify-content-between py-2 px-1 mb-4" >
      <span class="f15">{{$t('Clinic name')}}</span>
      <span class="primary--text f14">{{doctor.clinic.name}}</span>
    </div>
    <div class="d-flex justify-content-between py-2 px-1 mb-4" >
      <span class="f15">{{$t('Membership type')}}</span>
      <span class="primary--text f14 fw-bold">{{memberRole}}</span>
    </div>
    <div class="d-flex justify-content-between py-2 px-1 mb-4" v-if="doctor.membership && doctor.membership.createDate">
      <span class="f15">{{$t('Membership date')}}</span>
      <span class="primary--text f14 fw-bold">{{ doctor.membership.createDate | moment("dddd , jD jMMMM jYYYY") }} </span>
    </div>
    <div class="d-flex justify-content-between py-2 px-1 mb-4" v-else-if="doctor.membership && doctor.membership.updateDate">
      <span class="f15">{{$t('Membership date')}}</span>
      <span class="primary--text f14 fw-bold">{{ doctor.membership.updateDate | moment("dddd , jD jMMMM jYYYY") }} </span>
    </div>
    <div class="d-flex justify-content-between py-2 px-1 mb-4" v-if="doctor.membership && doctor.membership.memberFee">
      <span class="f15">{{$t('Clinic amount')}}</span>
      <span class="primary--text f14">{{doctor.membership.memberFee}}%</span>
    </div>
    <div class="d-flex justify-content-between py-2 px-1 mb-4" v-else>
      <span class="f15">{{$t('Clinic amount')}}</span>
      <span class="primary--text f14">{{$t('Not determined')}}</span>
    </div>


    <!-- ------------------------ -->
    <!-- Secretaries -->
    <div class="d-flex my-2 align-items-center">
      <span class="ms-0 me-3 fw-bold f14">{{$t('Secretary')}}</span>
      <v-divider></v-divider>
    </div>

    <div v-if="secretaries && secretaries.length > 0">
      <div v-for="(member, index) in secretaries" :key="index">
        <v-card
          v-if="member.registered || member.doctorFullName.trim()"
          class="d-flex align-center py-2 my-2 rounded-lg border"
          @click="openDrBottomSheet(member.doctorId)"
          flat
        >

          <v-avatar size="72" class="mx-3">
            <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="member.pic != null ? member.pic : require('@/assets/images/profile.jpg')"></v-img>
          </v-avatar>
          <span class="f15 mx-1 grey--text text--darken-4" >
            {{ member.doctorFullName }}
          </span>
        </v-card>
        <v-card v-else class="d-flex align-center py-2 my-2 rounded-lg border border-red" flat>
          <v-avatar size="72" class="mx-3 border border-red">
            <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="member.pic != null ? member.pic : require('@/assets/images/profile.jpg')"></v-img>
          </v-avatar>
          <div>
            <span class="f15 d-block mx-1 red--text red--darken-4 ltr" >
              {{ member.mobile }}
            </span>
            <span class="f12 d-block mx-1 red--text red--darken-4" >
              ({{ $t('Awaiting confirmation') }})
            </span>
          </div>
        </v-card>
      </div>
    </div>

    <span class="text-center f16 d-block mt-8" v-else>
      {{$t('No results found')}}
    </span>
    
    <!-- ------------------------ -->
    <!-- Schedule -->
    <div class="d-flex my-2 align-items-center">
      <span class="ms-0 me-3 fw-bold f14">{{$t('Schedule a patient')}}</span>
    </div>

    <v-list-item class="px-0 mx-0 d-none">
      <v-list-item-icon class="ms-0 me-2">
        <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
          class="d-flex align-items-center justify-content-center rounded-lg">
          <iconly type="bulk" name="calendar" color="#7E55F3" />
        </v-sheet>
      </v-list-item-icon>
      <v-list-item-content class="">
        <v-list-item-title class="font-weight-bold f13">{{ $t('Emergency call') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-items-center">
        <toggle-button
          @change="onChangeEmergencyCallStatus"
          :value="emergencyCallStatus"
          :sync="true"
          :labels="{checked: $t('Active'), unchecked: $t('Inactive')}"
          :font-size="12"
          :height="35"
          :width="85"
          :color="{checked: '#20B1EA', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
        >
        </toggle-button>
        <iconly type="light" name="arrow-left2" color="#757575" class="ms-2 me-0" />
      </v-list-item-action>
    </v-list-item>

    <v-list-item class="px-0 mx-0">
      <v-list-item-icon class="ms-0 me-2">
        <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
          class="d-flex align-items-center justify-content-center rounded-lg">
          <iconly type="bulk" name="calendar" color="#7E55F3" />
        </v-sheet>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold f13">{{ $t('Online') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-items-center">
        <toggle-button
          @change="onChangeOnlineCallStatus"
          :value="onlineCallStatus"
          :sync="true"
          :labels="{checked: $t('Active'), unchecked: $t('Inactive')}"
          :font-size="12"
          :height="35"
          :width="85"
          :color="{checked: '#20B1EA', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
        >
        </toggle-button>
        <iconly type="light" name="arrow-left2" color="#757575" class="ms-2 me-0" />
      </v-list-item-action>
    </v-list-item>

    <v-list-item class="px-0 mx-0">
      <v-list-item-icon class="ms-0 me-2">
        <v-sheet color="#EEE8FF" elevation="0" height="36" width="36"
          class="d-flex align-items-center justify-content-center rounded-lg">
          <iconly type="bulk" name="calendar" color="#7E55F3" />
        </v-sheet>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="font-weight-bold f13">{{ $t('Telephone') }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="d-flex flex-row align-items-center">
        <toggle-button
          @change="onChangePhoneCallStatus"
          :value="phoneCallStatus"
          :sync="true"
          :labels="{checked: $t('Active'), unchecked: $t('Inactive')}"
          :font-size="12"
          :height="35"
          :width="85"
          :color="{checked: '#20B1EA', unchecked: '#CCCCCC', disabled: '#CCCCCC'}"
        >
        </toggle-button>
        <iconly type="light" name="arrow-left2" color="#757575" class="ms-2 me-0" />
      </v-list-item-action>
    </v-list-item>

    <BshDoctor ref="drBottomSheet" :fromDoctorApp="true" />

  </section>
</template>

<script>
import BshDoctor from "@/components/DoctorProfile.vue";
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  components: {
     BshDoctor
  },
  data() {
    return {
      doctor: {
        clinic: {}
      },
      memberRole: '',

      secretaries: [],

      emergencyCallStatus: false,
      onlineCallStatus: false,
      phoneCallStatus: false,
    }
  },
  methods: {
    openDrBottomSheet(id){
      this.$refs.drBottomSheet.openWithDoctorId(id);
    },
    getDoctorInfoByMyMobile() {
      apiService
        .getDoctorInfoByMyMobile()
        .then((response) => {
          this.doctor = response.data;

          this.emergencyCallStatus = response.data.emergencyCallStatus;
          this.onlineCallStatus = response.data.onlineCallStatus;
          this.phoneCallStatus = response.data.phoneCallStatus;

          this.getFeeRoleConfig(this.doctor.roleCode);
          // this.getClinicMemberWithRole(this.doctor.clinic.id);
        })
        .catch((err) => {
          console.log(err);
          this.$router.go(-1);
        });
    },

    // getClinicMemberWithRole(id){
    //   apiService.getClinicMemberWithRole(id)
    //     .then((response) => {
    //       this.secretaries = [];
    //
    //       response.data.forEach(element => {
    //         if(element.roleCode == "2") {
    //           this.secretaries.push(element);
    //         }
    //       });
    //
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //       this.$router.go(-1);
    //     })
    // },

    getFeeRoleConfig(roleCode){
      apiService.getFeeRoleConfig(roleCode)
        .then((response) => {         
          this.memberRole = response.data.roleName
        })
        .catch((err) => {
          console.log(err)
          this.$router.go(-1);
        })
    },
    onChangeEmergencyCallStatus(){
      apiService.configSchedulingStatus("EMERGENCY_CALL", !this.emergencyCallStatus)
        .then(() => {         
          this.emergencyCallStatus = !this.emergencyCallStatus;
        })
        .catch((err) => {
          console.log(err);
        })
    },
    onChangeOnlineCallStatus(){
      apiService.configSchedulingStatus("ONLINE_CALL", !this.onlineCallStatus)
        .then(() => {         
          this.onlineCallStatus = !this.onlineCallStatus;
        })
        .catch((err) => {
          console.log(err);
        })
    },
    onChangePhoneCallStatus(){
      apiService.configSchedulingStatus("PHONE_CALL", !this.phoneCallStatus)
        .then(() => {         
          this.phoneCallStatus = !this.phoneCallStatus;
        })
        .catch((err) => {
          console.log(err);
        })
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  created(){
    this.getDoctorInfoByMyMobile();
  },
  // watch: {
  //   selectedCat: function () {
  //     this.page = 1;
  //     if (this.selectedCat == 0) {
  //       this.getAllExams(0, this.size);
  //     }
  //     else {
  //       this.getExamsByGroupId(0, this.size);
  //     }
  //   },
  // },
}
</script>
